import React from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../static/css/shares.css';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import { RedditShareButton, RedditIcon } from 'react-share';


import ReactGA from 'react-ga4';
ReactGA.initialize('G-K7G76JLJ7T');

export default function Shares({score, word1, word2, image=[]}) {

	const { t } = useTranslation();

	var text = t("whatsappText");
	text = text.replace("GAME", "Word Ladder").replace("SCORE", score);

	var detectedLanguage = window.location.pathname.split('/')[1];
    if(detectedLanguage == "" || detectedLanguage.length > 2) {
    	detectedLanguage = "en";
    }
    var link = window.location.href + "/play";
    const [linkCopied, setLinkCopied] = useState(false);

	function sendEvent(platform) {
		ReactGA.event({
            category: 'Game',
            action: 'Share' + platform
        });
	}

	function copyLink() {
		ReactGA.event({
            category: 'Game',
            action: 'CopyLink'
        });
        setLinkCopied(true);

        if(image != []) {
             var copyText = "Word Ladder " + word1 + " -> " + word2 + "\n";
             copyText += "My result - " + score + " words" + "\n";
             for(var i = 0; i < image.length; i++) {
                for(var j = 0; j < word2.length; j++) {
                    if(word2[j] == image[i][j]) { copyText += "🟩"; }
                    else { copyText += "⬜"; }
                }
                copyText += "\n";
             }
             navigator.clipboard.writeText(copyText + link);
        } else {
			navigator.clipboard.writeText(link);
        }
	}

	return (
		<div>
			<br/><br/>
			<h3>{t("shareHeader")}</h3>
			<p>{t("shareButtons")}</p>

			{ /* <p>{t("shareText")}</p>
            <input className="link" type="text" defaultValue={link} readonly="readonly"/><br/>  */ }
            <Button className="default-btn" variant="primary" onClick={copyLink}>{t("copyLink")}</Button><br/>
            {linkCopied
                ? <p className="linkCopied">{t("linkCopied")}</p>
                : <></>

            }

			<div>
		      <FacebookShareButton url={link} title={text} className="btn-share">
		        <FacebookIcon size={50} round={false} borderRadius={14}  onClick={() => sendEvent("Fb")} />
		      </FacebookShareButton>

		      <TwitterShareButton url={link} title={text} className="btn-share" >
		        <TwitterIcon size={50} round={false} borderRadius={14} onClick={() => sendEvent("Twitter")} />
		      </TwitterShareButton>

		      <WhatsappShareButton url={link} title={text} className="btn-share">
		        <WhatsappIcon size={50} round={false} borderRadius={14} onClick={() => sendEvent("Whatsapp")} />
		      </WhatsappShareButton>

		      <RedditShareButton url={link} title={text} className="btn-share">
		        <RedditIcon size={50} round={false} borderRadius={14}  onClick={() => sendEvent("Reddit")} />
		      </RedditShareButton>
		    </div>
            <br/>


	    </div>
    );
}