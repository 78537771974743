import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';


const Cookie = () => {

	const tilesetHtml =  (
        <div className="mainPart">
        <Helmet>
            <title>Word Ladder - Privacy Policy</title>
            <meta name="description" content="Privacy policy of the website www.word-ladder.com" />
            <link rel="canonical" href="https://www.word-ladder.com/cookie" />
        </Helmet>
        <Container>
            
			<h2>Privacy policy of the website www.word-ladder.com</h2>
			<br/>
			
			<ol type="I">
				<li>General Provisions</li>
				<li>Purpose and scope of data collection and data recipients; the basis for data processing</li>
				<li>The right to control, access and correct your data</li>
				<li>Cookies</li>
				<li>Copyright</li>
				<li>Users</li>
				<li>Liability</li>
				<li>Modification of the Privacy Policy</li>
				<li>Final Provisions</li>
			</ol>


			<p>This privacy policy of the website www.word-ladder.com (hereinafter: “Privacy Policy”) sets forth
			the conditions for using the service provided by the Administrator electronically via the
			telecommunications network at www.word-ladder.com.</p>


			<br/>
			<h4>DEFINITIONS</h4>

			<p><b>Administrator</b> - Lean House spółka z ograniczoną odpowiedzialnością, ul. Krucza 5/11, 00-548 Warsaw, entered into the
			Register of Entrepreneurs of the National Court Register kept by the District Court for the Capital City of Warsaw under KRS no.:
			0000718147, Tax Identification Number (NIP): 5213813853, Polish National Business Registry Number (REGON): 369500538, e-mail: contact@quizony.com</p>

			<p><b>Service</b> - Services described in section I of the Privacy
			Policy provided by the Administrator electronically</p>

			<p><b>User</b> - any entity using the Website www.word-ladder.com</p>

			<p><b>Website</b> - internet application posted at the following
			          internet address www.word-ladder.com</p>

			<p><b>Rules and Regulations</b> - a separate document setting out the rules for
			                        using the services provided via the website
			                        www.word-ladder.com, available at the following
			                        internet address: www.word-ladder.com/privacy.html</p>



			<br/>

			<h4 className="bolder">I. General Provisions</h4>
			<ol type="1">
			<li>The privacy policy regulates the provision of Services and the use of website
			www.word-ladder.com by the Users.</li>
			<li>In order to use the Services, the User is obliged to read the wording of the Privacy Policy
			and then to accept it in accordance with the provisions of Section VI.</li>
			<li>The Controller of personal data collected via the website www.word-ladder.com is Lean House
			spółka z ograniczoną odpowiedzialnością, ul. Krucza 5/11, 00-548
			Warsaw, entered into the Register of Entrepreneurs of the National Court Register kept
			by the District Court for the Capital City of Warsaw under KRS no.: 0000718147, Tax
			Identification Number (NIP): 5213813853, Polish National Business Registry Number
			(REGON): 369500538, e-mail: contact@quizony.com</li>

			<li>The service consists in enabling Users to use websites www.word-ladder.com, i.e. via:
			<ol type="a">
			<li>Playing games;</li>
			<li>Sharing games via Messenger, Facebook, Twitter, WhatsApp, Instagram, Snapchat and SMS.</li>
			</ol>
			</li>

			<li>The minimum technical requirements for the use of the Services by Users are:
			<ol type="a">
			<li>Internet access;</li>
			<li>A device with an installed browser.</li>
			</ol>
			</li>

			<li>If the provision of Services requires the processing of personal data of persons using the
			website www.word-ladder.com, it is processed in accordance with Regulation (EU)
			2016/679 of the European Parliament and of the Council of 27 April 2016 on the
			protection of individuals in connection with the processing of personal data and on the
			free movement of such data, and repealing Directive 95/46 / EC (General Data Protection
			Regulation) (hereinafter: “GDPR”).</li>
			<li>The Controller takes special care to protect the interests of data subjects, and in
			particular ensures that the data collected by them is processed in accordance with the
			law; collected for specified, lawful purposes and not subjected to further processing
			inconsistent with these purposes; factually correct and adequate in relation to the
			purposes for which it is processed and stored in a form that allows for the identification
			of persons to whom it relates, no longer than it is necessary to achieve the purpose of
			processing.</li>

			</ol>


			<h4 className="bolder">II. Purpose and scope of data collection and data recipients; the basis for data processing</h4>
			<ol type="1">
			<li>The Administrator collects Users' personal data for the following purposes:
			
			<li>Provision of Services by the Administrator;</li>
			<li>Direct marketing of the Administrator's products or services, subject to the
			possibility of marketing products or services of third parties;</li>
			<li>Ensuring the security of the Website and the protection of its Users' data.
			</li>
			</li>

			<li>The Administrator may also process data related to the use of the Website to generate
			statistics on the use of the website.</li>
			<li>The Administrator may process Users' personal data in the scope of data obtained using
			cookies to the extent that it may constitute personal data and data obtained via
			Facebook, Twitter, WhatsApp, Instagram and Snapchat.</li>
			<li>The Administrator may obtain Users' data directly from Facebook, Twitter, WhatsApp,
			Instagram and Snapchat. in the event that the User is interested in sharing the results of
			the quiz on these portals, or likes the unlimited-tiles Fanpage. In this case, obtaining this data
			is necessary to use the Services in this form.</li>
			<li>The basis for the processing of Users' personal data is to enable Users to use the Services.
			In the case of data processing for direct marketing of the Administrator's own products
			or services, the basis for such processing is the fulfilment of legally justified purposes
			carried out by the Administrator.</li>
			<li>The Administrator may publish commercial information, banners and links to other
			websites on the Website. By using the banner or link, the User goes to the website
			belonging to another owner, who is fully liable for the content posted there, as well as the
			processing of personal data of visitors to the website.</li>
			<li>The Administrator entrusted the processing of personal data to Lean House sp. z o.o.</li>
			<li>The Administrator provides personal data to the following entities, pursuant to art. 6 sec.
			1(a) of GDPR, i.e. based on the User's voluntary consent.</li>

			</ol>

			<h4 className="bolder">III. The right to control, access and correct your data</h4>
			<ol type="1">
			<li>The User has the right to access their personal data and correct them.</li>
			<li>Each person has the right to control the processing of data relating to them, contained in
			the Administrator's data set, and in particular the right to: request completing, updating,
			rectifying personal data, temporarily or permanently suspending its processing or
			removing it if it is incomplete or out of date, false or has been collected in violation of the
			Act or is no longer necessary to achieve the purpose for which it was collected, as well as
			the right to lodge a complaint with the supervisory body dealing with the protection of
			personal data.</li>
			<li>If the User grants consent to the processing of data for direct marketing of the
			Administrator's own products or services, this consent may be revoked at any time.</li>
			<li>If the Administrator intends to process or processes the User's data for the purpose of
			direct marketing of the Administrator's own products or services, the data subject is also
			entitled to:
			
			<li>submit a written, motivated request to cease processing of its data due to their
			special situation; or</li>
			<li>object to the processing of their data.
			</li>
			</li>
			<li>In order to exercise the rights referred to above, one can contact the Administrator by
			sending an appropriate message to the following address: contact@quizony.com or by
			e-mail to the Administrator's address indicated in this Privacy Policy.</li>

			</ol>

			<h4 className="bolder">IV. Cookies</h4>
			<ol type="1">
			<li>The Website uses cookies.</li>
			<li>Cookie files (“Cookies”) are IT data, in particular text files, which are stored on the
			Website User's end device and are intended for the use of the Website. Cookies usually
			contain the name of the website they come from, the storage time on the end device and
			a unique number.</li>
			<li>The entity that places cookies on the Website User's end device and accesses them is the
			Administrator.</li>
			<li>Cookies are used for the following purposes:
			
			<li>creating statistics that help to understand how Website Users use websites, which
			allows for improving their structure and content;</li>
			<li>enabling logging into the website;</li>
			<li>maintaining the Website User's session (after logging in), thanks to which the
			User does not have to re-enter the login and password on each subpage of the
			Website;</li>
			<li>determining the User's profile in order to display materials matched to this
			profile, in advertising networks and in particular in the Google network.
			</li>
			</li>
			<li>The following types of cookies are used within the Website:
			
			<li>session cookies - temporary files that are stored on the User's end device until
			logging out, leaving the website or turning off the software (web browser).</li>
			<li>persistent cookies - cookies that are stored on the User's end device for the time
			specified in the cookie file parameters or until they are deleted by the User. These
			type of cookies is stored on the user's hard drive to determine which visitors are
			new to the website and which visitors are coming back.</li>
			<li>third-party cookies - cookies placed on the Website by suppliers other than the
			Administrator and saved by them. Cookies of this type allow for adjusting
			advertisements to the preferences of the Users.
			</li>
			</li>
			<li>Software for browsing websites (web browser) usually allows cookies to be stored on the
			User's end device by default. Website Users can change the settings in this regard. The
			web browser allows for deleting cookies. It is also possible to automatically block
			cookies. Detailed information on this subject can be found in the help section or
			documentation of the web browser.</li>
			<li>Restrictions on the use of cookies may affect some of the functionalities available on the
			Website pages.</li>
			<li>Cookies placed on the Website User's end device may also be used by advertisers and
			partners cooperating with the Website operator.</li>
			<li>The Website may also contain links to the Administrator's sites or channels on external
			websites (in particular Facebook, Twitter). When using these links, the User leaves the
			Website. The Administrator has no control or influence on what data is collected by the
			indicated providers. In order to obtain information on the purpose and scope of data
			collection, including Cookies used there, their further processing and use by the
			indicated suppliers as well as the User's rights and options for privacy protection
			settings of these providers, the User should read the information on data protection
			(policy privacy) of the respective provider. If the User does not want the above-

			mentioned social channels to collect information about them after entering the Website,
			they should log out of these websites before entering the Website.</li>
			<li> If the User does not want to receive cookies, they may change the browser settings. We
			reserve that disabling cookies necessary for authentication processes, security,
			maintaining user preferences may make it difficult, and in extreme cases may prevent the
			use of the Website.</li>
			<li>Users can find more information on cookies in the help section of the web browser they
			use.</li>
			</ol>
			
			

			<h4 className="bolder">V. Copyright</h4>
			<ol type="1">
			<li>All materials, graphic elements, layout and composition of these elements, trademarks,
			and other information available on the website www.word-ladder.com are the subject of the
			Administrator's exclusive rights, with the exception of materials from shutterstock.</li>
			<li>Downloading or using the materials in any scope available on the
			website www.word-ladder.com requires the consent of the Administrator.</li>
			<li>Downloading materials cannot violate the Privacy Policy, Regulations, generally
			applicable law, as well as the interests of the Administrator.</li>
			<li>All graphic elements, technical solutions and other elements of the www.word-ladder.com
			website, in particular HTML and XHTML code, CSS sheets, JavaScript scripts and
			multimedia links are subject to protection by the Administrator's copyright.
			</li>
			<li>Subject to the provisions of sec. 4 above, all content on the website www.word-ladder.com is
			protected by copyright and is the property of the Administrator. The User bears full
			liability for any damage caused to the Administrator, resulting from the use of any
			content without the Administrator's consent.
			</li>
			</ol>

			<h4 className="bolder">VI. Users</h4>
			<ol type="1">
			<li>The acceptance of the Privacy Policy by the User takes place by reading and accepting its
			content, the link to which appears when www.word-ladder.com website is displayed for the
			first time.

			</li>
			<li>The User may accept the Privacy Policy at a later time when using the website
			www.word-ladder.com, however, in order to obtain the results of the quiz, it is necessary to
			accept the Privacy Policy.</li>
			<li>The User is obliged to comply with the provisions of the Privacy Policy, and in particular
			to use the website www.word-ladder.com in accordance with its intended purpose and
			applicable law.</li>
			<li>The User's actions relating to the use of the Services should be carried out in accordance
			with the Privacy Policy, applicable law and good manners.</li>
			</ol>
			

			<h4 className="bolder">VII. Liability</h4>
			<ol type="1">
			<li>The Administrator is responsible for the correct operation of the www.word-ladder.com
			website and its functionalities.</li>
			<li>The Administrator is not liable for the Users' actions within the www.word-ladder.com
			website and within their use of the Services.</li>
			<li>The Service Provider is not responsible for the effectiveness of the actions performed by
			the User with the help of the Services, if they did not result from a defective or harmful
			operation of the Services or the website www.word-ladder.com.</li>
			<li>Actions that actually or potentially hinder or prevent the proper operation of the
			www.word-ladder.com website and the Services are prohibited. The previous sentence shall
			apply mutatis mutandis in cases of action actually or potentially to the detriment of the
			Administrator, in particular a negative impact on the reputation or good name of the
			Administrator.</li>
			<li>The User bears sole and personal liability for the use of the website www.word-ladder.com
			that is illegal and inconsistent with the Privacy Policy, including criminal and civil
			liability.</li>
			<li>The Administrator is not liable for the manner, quality and frequency of operation of the
			networks of mobile and fixed telephony operators, as well as Internet providers.</li>
			</ol>
			

			<h4 className="bolder">VIII. Modification of the Privacy Policy</h4>
			<ol type="1">
			<p>The Administrator may modify the Privacy Policy and the formula of the Services provided, if at
			least one of the important reasons indicated in the catalogue below occurs:

			
			<li> Amendment to the provisions of applicable law that regulates the conduct of
			business by the Administrator;</li>
			<li>The need to adjust the wording of the Privacy Policy to the applicable law or the
			need to make editorial changes to the Privacy Policy;</li>
			<li>Modification of the operation or functioning of the website www.word-ladder.com or
			Services, which will be caused by objective and independent reasons of
			technological or technical nature;</li>
			<li> Modification of the terms of use of the website www.word-ladder.com or Services,
			not deteriorating the situation of Users compared to the existing ones;</li>
			<li>The need to update the Administrator's data indicated in the Privacy Policy.</li>
			</p>
			</ol>

			<h4 className="bolder">IX. Final Provisions</h4>
			<ol type="1">
			<li>Website may contain links to other websites. The Administrator suggests reading the
			privacy policy also after going to other websites. This Privacy Policy applies only to the
			website www.word-ladder.com and the provided Services.</li>
			<li> The Administrator shall take technical and organizational measures to protect the
			personal data being processed, appropriate to the risks and the categories of data
			protected, and in particular they shall secure the data against unauthorized disclosure,
			removal by an unauthorized person, processing in violation of applicable laws and
			modification, loss, damage or destruction.</li>
			<li> The Administrator provides appropriate technical measures to prevent the acquisition
			and modification of personal data sent electronically by unauthorized persons.</li>
			<li> In matters not covered by this Privacy Policy, the provisions of the Polish Civil Code of 23
			April 1964 (Dz.U. [Polish Journal of Laws] of 1964 No. 16, item 93, as amended) shall
			apply.</li>
			<li>Contact in all matters in the scope of support and technical assistance takes place via
			messages sent to the following e-mail address: contact@quizony.com.</li>

			</ol>
			


			


        </Container>
        </div>
      );

    return tilesetHtml
}

export default Cookie;

