
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Instruction from './../Instruction';
import Shares from './../Shares';
import general from './../../static/json/general.json';
import words from './../../static/json/words.json';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-K7G76JLJ7T');

function todayDate() {
	const today = new Date();
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1; // Months start at 0!
	let dd = today.getDate();

	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;

	const dateToday = yyyy + "-" + mm + "-" + dd;
	return dateToday;
}

export default function Ladder({view, onGameFinish, chooseTileset, playNextLevel, level}) {

	const start = general["ladder"][Number(level)-1]["start"];
    const end = general["ladder"][Number(level)-1]["end"];
    const bestresult = general["ladder"][Number(level)-1]["result"];

    const len = start.length;

	const [attempts, setAttempts] = useState(0);
	const [guesses, setGuesses] = useState([start]);
	const [errorMessage, setErrorMessage] = useState("");
	const [helpShown, setHelpShown] = useState(false);

	const [hintsNumber, setHintsNumber] = useState(0);

//	var wordList = require('word-list-json');
//	let result = wordList.filter((word) => word.length === 5);
//	result = result.map((word) => word.toUpperCase());
//	result.sort();
//    console.log(result.length);
//    console.log(JSON.stringify(result));



	if(view == "play") {
		var lastPassed = localStorage.getItem("ladder-lastPassed");

		var nextLevel = 1;
		if(lastPassed == undefined || lastPassed == null) {
			nextLevel = 1;
		} else {
			nextLevel = Number(lastPassed) + 1;
			var allLevels = general["ladder"].filter(tset => tset["date"] <= todayDate()).length;
			if(level > allLevels) {
				var url = window.location.href;
				var newUrl = url.replace(level, "levels");
                window.location.href = newUrl;
			}
		}
		var url = window.location.href;
		if(nextLevel < level) {
			var newUrl = url.replace(level, nextLevel);
	        window.location.href = newUrl;
		}
	}

	var arr = (len == 4) ?  ["","","",""] : ["","","","",""];
	const [nextGuess, setNextGuess] = useState(arr);

	const [nextLetter, setNextLetter] = useState(0);
	const { t } = useTranslation();

	function guess() {

		var newGuess = nextGuess.join("");
		if(newGuess.length < end.length) {
			setErrorMessage("Too short word.");
			return;
		}

		if(!words["words" + len].includes(newGuess)) {
			setErrorMessage("Word " + newGuess + " doesn't exist.");
			return;
		}
		var diff = 0, last = guesses[guesses.length-1];
		console.log(last);
		for(var i = 0; i < end.length; i++) {
			if(newGuess[i] != last[i]) {
				diff++;
			}
		}
		if(diff == 0 || diff > 1) {
        	setErrorMessage("You have to change exactly 1 character.");
        	return;
        }

		setHelpShown(false);
		setGuesses([...guesses, newGuess]);
        setAttempts(attempts+1);
        var arr = (len == 4) ?  ["","","",""] : ["","","","",""];
        setNextGuess(arr);
        setNextLetter(0);
        setErrorMessage("");

		if(newGuess == end) {
			setAttempts(guesses.length);
			onGameFinish(guesses.length);
		}
	}

	function skipThisLevel() {
		setAttempts(1000);
		onGameFinish(1000);
	}

	function nothing() {}

	function set(id, val) {

		console.log(id + " " + val);

		if(val.length < 1) { return; }
		if(val.length > 1) {
			var current = nextGuess[id];
			console.log(current);
			if(val[0] != current) {val =val[0]; }
			else {val = val[1];}
		}
		if(true) {
			var nextGuessCopy = [...nextGuess];
        	nextGuessCopy[id] = val.toUpperCase();
        	setNextGuess(nextGuessCopy);
        	if(id < len-1) {setNextLetter(id+1); }
		}
	}

	function removeLast() {
		if(guesses.length == 1) {return; }
		var lastWord = guesses[guesses.length - 1];
		setGuesses(guesses.slice(0,-1));
		setNextGuess(lastWord.split(""));
		setNextLetter(len-1);
		setHelpShown(false);
	}

	function clear(id) {
		if(nextGuess[id] == "") {
			if(id == 0) { removeLast(); return; }
			id--;
		}
		var nextGuessCopy = [...nextGuess];
        nextGuessCopy[id] = "";
        setNextGuess(nextGuessCopy);
        setNextLetter(id);
	}

	function click(id, key) {

		if(key === "Enter") {
			guess();
		} else if(key === "Backspace") {
			clear(id);
		}
	}

	function WordRow({word}) {

		return (<Row className={"guessRowButton" + len} >

				{word.split("").map((gss,id) =>
                    <div className={"rowLetter " + (word[id] == end[id] ? "goodLetter" : "")} >{word[id]}</div>
                )}
			</Row>)
	}

	function InputRow() {

		return (<Row className={"guessRowButton" + len} >
				{nextGuess.map((gss,id) =>
					<Form.Control as="input" maxLength="2" className={"rowLetter " + (gss == end[id] ? "goodLetter" : "")}
						value={gss} autoFocus={nextLetter == id}
					    onKeyDown={(e) => click(id, e.key) }
                        onChange={(e) => set(id, e.target.value)}
					     />
				)}

			</Row>)
	}

	function isOneLetterDiff(word1, word2) {

		let diffCount = 0;
		for (let i = 0; i < word1.length; i++) {
			if (word1[i] !== word2[i]) diffCount++;
			if (diffCount > 1) return false;
		}
		return diffCount === 1;
	}

	function HelpRow() {

		if(!helpShown) {
			return (<><Button className="small-btn help-btn" onClick={showHelp}>HELP</Button><br/></>);
		} else {

			var last = guesses[guesses.length-1];
			var similar = words["words" + len].filter(w => isOneLetterDiff(last, w));

			return (<div className="similarWords">
			Words similar to {last}: <br/>
			 { similar.map(sw => <span className="similarWord">{sw}</span>) }
			 </div>);
		}

	}

	function showHelp() {
		setHelpShown(true);

		ReactGA.event({
            category: 'Game',
            action: 'ShowHint',
            value: hintsNumber+1
        });
        setHintsNumber(hintsNumber+1);
	}

	const boardCode = (<div className="fullBoard">

			<>
				<h1> Change {start} to {end} </h1>
				<p> You can change one letter at a time. </p>
				<br/>

				{ view == "play"
				?   <>
						{ /*<WordRow word={start}/> */ }
						{ guesses.map(gss => <WordRow word={gss}/>) }
                        <InputRow/>
                        <Button className="small-btn enter-btn" onClick={guess}>ENTER</Button>


                        <WordRow word={end}/>
                        {   errorMessage != "" ? <div className="guessError">{ errorMessage }</div> : ""}

                        <HelpRow/><br/><br/><br/>
                        <Button className="small-btn" onClick={skipThisLevel}>Skip this level </Button><br/>
					</>

				: <div>

					{ guesses.map(gss => <WordRow word={gss}/>) }

					{ attempts == 1000
					? <>
						<h4>You skipped this level, but you can return to it at any time. </h4>
					  </>
					: <>
						<br/><h1>{t('win')}</h1>
			            <h4>
			                { "You have created word " + end + " using " + attempts + " word" + (attempts > 1 ? "s" : "") + "!"}
			                <br/>
			                {
			                    (attempts <= bestresult) ? "It's the best possible score!"
			                    : "The best possible score is " + bestresult + "."
			                }
			            </h4>
					  </>

					}


		            <Button className="default-btn" onClick={playNextLevel}>Play next level </Button><br/>
		            <Button variant="success" className="small-btn" onClick={chooseTileset}>Show all levels</Button>


					<Shares score={guesses.length-1}  word1={start} word2={end} image={guesses}/>

					</div>
				}
			</>

	    </div>
	);

    return boardCode;
}

