import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { useState } from 'react';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';

import ReactGA from 'react-ga4';
import Minigames from './games/Minigames';
import Comments from './Comments';
import '../static/css/tilesets.css';
import GoogleAd from './GoogleAd';

import general from '../static/json/general.json';

function bigName(gameName) {
	const names = {
        "ladder": "WORD LADDER"
    };
    if(gameName in names) {
        return names[gameName];
    } else {
		return gameName.toUpperCase();
	}
}


function Tileset({game, tileset, pb, stars, name, date}) {

	const navigate = useNavigate();
	const { t } = useTranslation();
	const detectedLanguage = window.location.pathname.split('/')[1];

	const bestScore = localStorage.getItem(game + "-" + tileset + "-best");


	function getUrl({game, tileset}) {
		if(game === "ladder") {
	        return ("/" + detectedLanguage + "/" + tileset);
	    } else {
	        return ("/" + detectedLanguage + "/" + game + "/" + tileset);
	    }
	}

	function playGame({game, tileset}) {
	    ReactGA.event({
	        category: 'Game',
	        action: 'StartGame'
	    });
    }

	function playTileset({nextLevel, game, tileset}) {
		if(nextLevel == tileset) {
			ReactGA.event({
		        category: 'Game',
		        action: 'StartGame'
		    });
		    window.location.href = getUrl({game, tileset});
		}
    }

	var played = (bestScore != null);
	var lastPassed = localStorage.getItem( "ladder-lastPassed");
	var nextLevel = 1;
	if(lastPassed == undefined || lastPassed == null) {
		nextLevel = 1;
	} else {
		nextLevel = Number(lastPassed) + 1;
	}
	var tilesetAvailable = "";
	if(nextLevel < Number(tileset)) {
		tilesetAvailable = "tilesetHidden";
	}




	function getStars() {
		return (
			<div>
				{(stars >= 1) ? <img className="levelStar" src={"/images/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/star.png"}/>}
				{(stars >= 2) ? <img className="levelStar" src={"/images/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/star.png"}/>}
				{(stars >= 3) ? <img className="levelStar" src={"/images/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/star.png"}/>}

			</div>
		);

	}

    return (
        (<Col className='tilesetContainer'>
            <Card onClick={() => playTileset({nextLevel, game, tileset})} className={'tilesetCard ' + tilesetAvailable} >

                <Card.Body>
                    <Card.Title>LEVEL {tileset} </Card.Title>
					{ name }
					{ getStars() }
                    {
                        played
                        ? <>
                            { bestScore != 1000
                              ? <Card.Text>  Best score: {bestScore} attempt{(bestScore > 1 ? "s" : "")} </Card.Text>
                              : <Card.Text>  </Card.Text>
                            }

                            <Button href={getUrl({game, tileset})} className="small-btn light-btn" variant="primary"
                                                            onClick={() => playGame({game, tileset})}>Play again</Button>
                                                            </>
                        : nextLevel >= Number(tileset)
                        ?   <><Card.Text>  </Card.Text>
                            <Button href={getUrl({game, tileset})} className="small-btn" variant="primary"
                                onClick={() => playGame({game, tileset})}>{t("play")}</Button></>
                            :  ""

					}
					<br/>{ date }
                </Card.Body>
            </Card>
        </Col>)

    );
}

function todayDate() {
	const today = new Date();
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1; // Months start at 0!
	let dd = today.getDate();

	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;

	const dateToday = yyyy + "-" + mm + "-" + dd;
	return dateToday;
}

function getStars(game, level, pb) {
	const bestScore = localStorage.getItem(game + "-" + level + "-best");

	if(bestScore != null) {
		if(pb == bestScore) {
			return 3;
		} else if(bestScore <= pb + 3) {
			return 2;
		} else if(bestScore != 1000) {
			return 1;
		} else {
			return 0;
		}

	} else {
		return 0;
	}
}

function getLevels(game) {


	return general[game].filter(tset => tset["date"] <= todayDate()).map(tset => ({"id": tset["id"],
	"name": tset["start"] + " → " + tset["end"],
	"stars": getStars(game, tset["id"], tset["result"]), "pb": tset["result"], "date": tset["date"]}));
}



const Levels = ({game, language}) => {
	const { t } = useTranslation();
	var levels = getLevels(game);

	let detectedLanguage = window.location.pathname.split('/')[1];
	if(detectedLanguage === "") {
		detectedLanguage = "en";
	}

	function gameToUpper({game}) {
		const titles = {
			"tiles": "TILES",
        	"infinite": "INFINITE TILES",
        	"same": "SAME GAME",
        };
        if(game in titles) {
        	return titles[game];
        } else {
			return game.toUpperCase()
		}
	}

	function getPoints({tileset, game}) {
		tileset = tileset["id"];
		var best = Number(localStorage.getItem(game + "-" + tileset + "-best"));
		if(best == null) {
			return 0;
		} else {
			return best;
		}

	}

	function countPoints(game) {
		var stars = 0;

		for(var i = 0; i < levels.length; i++) {
			stars += levels[i]["stars"];
		}
		return stars;
	}


	const tilesetHtml = (
        <div>

	        <Helmet>
	            <title>Word Ladder - Available Levels</title>
	            <meta name="description" content={"Choose your favorite tileset and play " + game} />
	            <link rel="canonical" href={"https://www.word-ladder.com/" + detectedLanguage + "/levels"} />
	            <meta property="og:url" content={"https://www.word-ladder.com/" + detectedLanguage + "/levels"} />
	            <meta property="og:image" content="https://www.word-ladder.com/banner2.png" />
	            <meta property="og:title" content={"Word Ladder - " + gameToUpper({game}) + "- Available Tilesets"} />
	        </Helmet>


	        <div className="mainPart">
		        <Container>
		            <GoogleAd type="tilesetsTop"/>
		            <br/>

		            <h1>{bigName(game)}</h1>
		            <h2> New level every day! </h2>

		            <h2><img className="biggerStar" src={"/images/star.png"}/> { countPoints(game) + " / " + (3*levels.length) } </h2>

		            {
		                <Row>
		                    {levels.map(tileset => ( <Tileset game={game}  tileset={tileset["id"]} pb={tileset["pb"]} stars={tileset["stars"]}
		                        name={tileset["name"]} key={tileset["name"]} date={tileset["date"]} />))}
		                </Row>
		            }

		            <GoogleAd type="tilesetsBottom"/>
		        </Container>
				<br/>
	        </div>

			<Comments/>

        </div>
      );

    return tilesetHtml
}
export default Levels;

