import React from 'react';

import './style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import tile1 from './static/tile1.png'
import tile2 from './static/tile2.png'
import tile3 from './static/tile3.png'

import Ladder from './components/games/Ladder';

import Comments from './components/Comments';

import { Helmet } from 'react-helmet';

import Shares from './components/Shares';
import Minigames from './components/games/Minigames';

import GoogleAd from './components/GoogleAd';

import general from './static/json/general.json';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-K7G76JLJ7T');



function getResult(game, tileset, param) {
	return localStorage.getItem(game + "-" + tileset + "-" + param);
}

function setResult(game, tileset, param, result) {
	return localStorage.setItem(game + "-" + tileset + "-" + param, result);
}

function todayDate() {
	const today = new Date();
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1; // Months start at 0!
	let dd = today.getDate();

	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;

	const dateToday = yyyy + "-" + mm + "-" + dd;
	return dateToday;
}


function Main({view, game, play, finishGame, tileset, lang}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function chooseTileset() {
			if(game == "ladder") {
				window.location.href =  "/" + lang + "/levels";
			} else {
				window.location.href =  "/" + lang + "/" + game + "/levels" ;
			}
		}

		function playNextLevel() {
			var nextLevel = Number(tileset) + 1;
			var allLevels = general[game].filter(tset => tset["date"] <= todayDate()).length;
			if(nextLevel > allLevels) { nextLevel = "levels"; }
			if(game == "ladder") {
				window.location.href =  "/" + lang + "/" + nextLevel;
			} else {
				window.location.href =  "/" + lang + "/" + game + "/" + nextLevel ;
			}
		}

	function codeForGame() {
		if(game == "ladder") {
			return (<Ladder view={view} onGameFinish={finishGame} chooseTileset={chooseTileset} playNextLevel={playNextLevel} level={tileset}/>);
		}
	}

	var code = (
        <>
            <div className="game">
                {codeForGame()}
            	<br/>
        </div>
    </>);

    return (
        <>
	        <div className="mainPart">
		        <Container>
		            <GoogleAd type="top"/>
		            <Row>
		                { code }
		            </Row>
		            <GoogleAd type="bottom"/>
		        </Container>
	        </div>
	        <br/><br/>
        </>
      );
}



function Game({game, lang, tileset}) {

	const { t } = useTranslation();
    const [currentView, setCurrentView] = useState("play");
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    function finishGame(result) {

		var best = getResult(game, tileset, "best");
        setResult(game, tileset, "last", result);
		if(getResult(game, tileset, "best") == null) {
			setResult(game, tileset, "best", result);
		}

		if(getResult(game, tileset, "best") > result) {
            setResult(game, tileset, "best", result);
        }

        var lastPassed = Number(localStorage.getItem(game + "-lastPassed"));
        if(Number(tileset) > lastPassed) {
            localStorage.setItem(game + "-lastPassed", tileset);
        }

        var today = new Date();
		var dateToday = today.getFullYear() + "-" + (today.getMonth()+1) + "-" + today.getDate();

        var todayBest = getResult(game, tileset, dateToday);
        if(todayBest == null) {
            var allDates = getResult(game, tileset, "dates");
            if(allDates === null) {
                allDates = dateToday;
            } else {
                allDates = allDates + " " + dateToday;
            }
            setResult(game, tileset, "dates", allDates);
            setResult(game, tileset, dateToday, result);
        }

        if(todayBest > result) {
            setResult(game, tileset, dateToday, result);
		}


        ReactGA.event({
            category: 'Game',
            action: 'FinishGame',
            value: result
        });

		setCurrentView("finish");
	}

	function play() {
		ReactGA.event({
            category: 'Game',
            action: 'StartGame'
        });
    	setCurrentView("play");
    }


	const title = "Word Ladder - Level " + tileset;
	const description = "";


	return (
		<>
			<Helmet>
                <title>{title}</title>
                <link rel="canonical" href={"https://www.word-ladder.com/" + lang + "/" + tileset} />
                <meta property="og:url" content={"https://www.word-ladder.com/" + lang + "/" + tileset }  data-react-helmet="true" />
                <meta property="og:image" content={"https://www.word-ladder.com/images/games/" + game + "/default.png"} />
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={title}/>


            </Helmet>

			<Main view={currentView} game={game} play={play} finishGame={finishGame} tileset={tileset} lang={lang}/>


		</>

	);
};

export default Game;
